import React from 'reactn';
import translations from '../translations.yaml';

// Figure out the preferred language from localStorage or the user agent
let languages = ['en', 'nl'];
let language = localStorage.getItem('language');
if (languages.indexOf(language)<0) {
    for(let lang of navigator.languages) {
        lang = lang.split('-')[0];
        if (languages.indexOf(lang)>=0) {
            language = lang;
            break;
        }
    }
}
if (languages.indexOf(language)<0) lang = 'en';

// Helper method on string, to reuse short generic strings at the start of a sentence
String.prototype.ucfirst = function() {
    return this.slice(0,1).toUpperCase() + this.slice(1);
};

// Translations consist of HTML; so we must take care of escaping interpolations ourselves
String.prototype.escapeHtml = function() {
    return this
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

// The main translation function
export default function tr(name, props = {}, silentFail) {
    if (name instanceof Array && name.length===1) name = name[0];

    let tr = translations;
    for(let part of name.split('.')) {
        if (tr) tr = tr[part];
    }
    if (typeof tr === 'object') {
        let str = tr[language];
        if (!str) {
            if (silentFail) return;
            console.warn(`No ${language} translation for ${name}`);
            // Pick the first other language that is available
            for(let lang of languages) {
                str = tr[lang];
                if (str) break;
            }
        }
        if (str) {
            // String interpolations
            str = str.replace(/{([a-zA-Z0-9_]+)}/g, function(all, id) {
                let repl = props[id];
                if (typeof repl === 'string') repl = repl.escapeHtml();
                return repl;
            });

            // Return as HTML
            return str.indexOf('<')>=0 ? React.createElement('span', {dangerouslySetInnerHTML: {__html: str}}) : str;
        }
    } else {
        if (silentFail) return;
        console.warn(`No translations for ${name}`);
    }
    // Return a placeholder
    return <strong className="untranslated">@{name}</strong>
}

// Change the language and set it in local storage. The caller should make
// sure strings on the screen are updated.
tr.setLanguage = function(_language) {
    language = _language;
    localStorage.setItem('language', language);
};

// The current language.
tr.getLanguage = function() {
    return language;
};

// Returns a `tr` function that looks up translations using the given prefix.
// If not found, it'll look from the root after that.
tr.prefix = function(prefix) {
    return function(name, props = {}) {
        if (name instanceof Array && name.length===1) name = name[0];
        return tr(prefix+'.'+name, props, true) || tr(name, props)
    }
}