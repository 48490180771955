import React, { useState, useEffect } from 'reactn';
import * as rpc from '../services/rpc';
import Recorder from '../services/recorder';
import QRCode from 'qrcode.react';
import CopyText from '../components/copy-text';
import {withRouter, useHistory} from 'react-router-dom';
import { useMinutesLeft } from '../services/time-left';
import Translator from '../services/translator';
import * as detectBrowser from 'detect-browser';

import CheckIcon from "feather-icons/dist/icons/check-circle.svg";
import AlertIcon from "feather-icons/dist/icons/alert-circle.svg";
import BatteryIcon from "feather-icons/dist/icons/battery.svg";
import BatteryChargingIcon from "feather-icons/dist/icons/battery-charging.svg";
import PlayIcon from "feather-icons/dist/icons/play-circle.svg";
import StopIcon from "feather-icons/dist/icons/stop-circle.svg";
import Smile from "feather-icons/dist/icons/smile.svg";



const browser = detectBrowser.detect();

const tr = Translator.prefix('take_test');


export const TakeTest = withRouter(props => {

	// Use a global state variable for maintaining the step,
	// so the header can hook into this to show the step overview.
	let [stepNum, setStepNum] = React.useGlobal('takeTestStep');

	// Load test and attempt data from the server
    let testKey = props.match.params.testKey;
	let [dataLoadMsg, data] = rpc.useResult('/api/tests/'+testKey, 'POST', {candidateKey: localStorage.getItem('candidateKey_'+testKey)});
	
	if (data && stepNum==null) {
        // When data is first set
		localStorage.setItem('candidateKey_'+testKey, data.candidateKey);

		// Figure out which step to resume at
		stepNum = 0;
		for(let i = 0; i < steps.length; i++) {
			if (steps[i].completed && steps[i].completed(data.student ||{})) {
				stepNum = i+1;
			}
		}
	}

	let step = steps[stepNum];

    useEffect(() => {
		if (data) {
			// When we first get the data, set the stepNum we deduced above as a global, so
			// the header can reflect it.
			setStepNum(stepNum);
//			if (step.onEnter) step.onEnter(data);
		}
		return () => {
			// On unmount, make the steps overview in the header disappear
			setStepNum(null);
		};
	}, [data]);
	

	const [recorder] = useState(() => new Recorder());
	useEffect(() => {
		return () => { 
			// when the component goes away
			recorder.destroy();
		}
    }, []);

	if (dataLoadMsg) return dataLoadMsg;
	
	console.log('render step', stepNum);
    
	if (data && data.test.isExample) {
		// Fake the start time for example tests
		let now = new Date();
		now.setSeconds(now.getSeconds() + step.exampleTestDelay || 180);
		data.test.startTime = new Date(now).toISOString();
	}

	const nextStep = (stepNum < steps.length-1) ? (() => setStepNum(stepNum+1)) : null;

	let Content = step.component;

	return <div style={{paddingBottom: "75px"}}> { /* Room for chrome's recording-overlay. */}
		{step.mustRecord && <RestoreRecording recorder={recorder} data={data} />}
		<Content step={step} data={data} recorder={recorder} nextStep={nextStep} />
	</div>;
});


const RestoreRecording = ({recorder,data}) => {

	const tr = Translator.prefix('take_test.restore');

	let streams = recorder.useStreamStatus();

	let result = [], active = 0;

	if (streams.user==='active') {
		active++;
	} else {
		result.push(<li key="user">{tr`user_inactive`}</li>);
		if (streams.user==='off') recorder.add('user');
	}

	if (streams.screen==='active') {
		active++;
	} else {
		function retry(e) {
			recorder.add('screen');
			e.preventDefault();
		}
		let retryE = streams.screen!=='starting' && <a href="#" onClick={retry}>{tr`try_again`}</a>;
		console.log('retry', streams.screen, retryE)
		result.push(<li key="screen">{tr`screen_inactive`} {retryE}</li>);
		if (streams.screen==='off') recorder.add('screen');
	}

	if (streams.remote==='active') {
		active++;
	} else {
		let remoteKey = getRemoteKey();
		result.push(<li key="remote">{tr`remote_inactive`} <CopyText value={remoteKeyToUrl(remoteKey)} /></li>);
		if (streams.remote==='off') recorder.add('remote', remoteKey);
	}

	if (active && !recorder.isStarted()) recorder.start(`${data.testKey}-${data.candidateKey}`, data.encKey);

	return result.length ? <div className="toast toast-error mb-2">{tr`problems`}<ul>{result}</ul></div> : <></>;
};


export function TakeTestSteps(props) {
	return <ul className="xstep">
		{steps.map((item, index) => item.hidden ? null :
			<li className={`step-item ${props.step == index ? "active" : ""}`} key={index}>
				<a className="tooltip" data-tooltip={tr('step_num', {num:index+1})} disabled={true}>{item.getTitle()}</a>
			</li>
		)}
	</ul>
}


const Start = ({ data, nextStep }) => {
	const start = new Date(data.test.startTime)
	let minutesLeft = useMinutesLeft(data.test.startTime);
	let tooEarly = minutesLeft > 15;
	let tooLate = minutesLeft <= -30;
	let wrongBrowser = browser && browser.name !== 'chrome' && browser.name !== 'firefox';

	const tr = Translator.prefix('take_test.start');

	return <div className="side-info">
		<div className="info">
			{tr`intro`}
			<div className="columns mt-2">
				<div className="col-6" />
				<a className="column col-3" href="https://www.mozilla.org/nl/firefox/new/" target="_blank"><img className="img-responsive" src="/firefox.png" /></a>
				<a className="column col-3" href="https://www.google.com/chrome/" target="_blank"><img className="img-responsive" src="/chrome.png" /></a>
			</div>
		</div>
		<div>
			<h4>{tr`right_test`}</h4>
			<p>{tr`check_details`}</p>
			<table>
				<tbody>
					<tr><th>{tr`test`.ucfirst()}:</th><td>{data.test.name}</td></tr>
					<tr><th>{tr`teacher_email`.ucfirst()}:</th><td>{data.test.ownerEmail}</td></tr>
					<tr><th>{tr`date`.ucfirst()}:</th><td>{new Date(data.test.startTime).toDateString()}</td></tr>
					<tr><th>{tr`start_time`.ucfirst()}:</th><td>{start.toTimeString()}</td></tr>
				</tbody>
			</table>
			{
				tooLate &&
				<div className="dialog info-dialog">{tr`old_test`}</div>  
			}
			{
				tooEarly &&
				<div className="dialog info-dialog">
					{tr`future_test`}
					<div className="buttons">
						<a className="btn" href="/t/example" target="_blank">{tr`open_example_test`}</a>
					</div>
				</div>
			}
			{
				!tooEarly && !tooLate && minutesLeft > 0 && <div className="dialog info-dialog">{tr(`start_now`, {minutesLeft})}</div>  
			}
			{
				wrongBrowser &&
				<div className="toast toast-error">{tr`browser_not_supported`}</div>
			}
			<button className="next-button" disabled={tooEarly} onClick={nextStep}>{tr`correct`}</button>
		</div>
	</div>
}

const AboutYou = ({data, nextStep}) => {

	const history = useHistory();
	const tr = Translator.prefix('take_test.about_you');

    let student = data.student || {};
    let [fullName,setFullName] = useState(student.fullName||'');
    let [studentId,setStudentId] = useState(student.studentId||'');
    let [agreePrivacy,setAgreePrivacy] = useState(student.agreePrivacy||false);
	let [agreeHonour,setAgreeHonour] = useState(student.agreeHonour||false);
	
    let [saving, setSaving] = useState(false);

    let ready =
        fullName.length>4 && fullName.indexOf(' ') &&
        !isNaN(parseInt(studentId)) &&
        agreePrivacy &&
        agreeHonour;

    async function save(e) {
		e.preventDefault();
        if (!ready) return;
        setSaving(true);

        let res = await rpc.put(`/api/tests/${data.testKey}/${data.candidateKey}`, {fullName, studentId, agreePrivacy, agreeHonour});
        setSaving(false);
        if (res.error) {
            history.replace({ state: {flash: res.error, flashType: "error"}} );
        } else {
            nextStep();
        }
    }

	return <div className="side-info">
		<div className="info">{tr`intro`}</div>
        <form>
            <h3>{tr`your_data`}</h3>
            <fieldset className="about-you">
                <label className="form-group">
                    <span className="col-3 form-label">{tr`name`.ucfirst()}</span>
                    <input value={fullName} onChange={e => setFullName(e.target.value)} className="col-10 form-input" type="text" required />
                </label>
                <label className="form-group">
                    <span className="col-3 form-label">{tr`student_number`.ucfirst()}</span>
                    <input value={student.studentId} onChange={e => setStudentId(e.target.value)}  className="col-10 form-input" type="number" required />
                </label>
                <label className="form-group columns col-oneline">
                    <input checked={student.agreePrivacy} onChange={e => setAgreePrivacy(e.target.checked)} className="column col-auto form-input" type="checkbox" required />
                    <span className="column form-label">{tr`agree_privacy`}</span>
                </label>
                <label className="form-group columns col-oneline">
                    <input checked={student.agreeHonour} onChange={e => setAgreeHonour(e.target.checked)} className="column col-auto form-input" type="checkbox" required />
                    <span className="column form-label">{tr`agree_honour`}</span>
                </label>
            </fieldset>
			<button className={"next-button"+(saving ? " loading": "")} disabled={!ready || saving} onClick={save}>{tr`save`.ucfirst()}</button>
        </form>
    </div>
}

const Cameras = ({data, recorder, nextStep}) => {
	const user = 'user', screen = 'screen', remote = 'remote'
	const [starting, setStarting] = useState(false);

	const tr = Translator.prefix('take_test.cameras');

    async function start() {
        setStarting(true);
        await recorder.start(`${data.testKey}-${data.candidateKey}`, data.encKey);
        nextStep();
	}
	let streams = recorder.useStreamStatus()

	let userConfigured = streams[user]=="active" && streams[screen]=="active"
	let remoteConfigured = streams[remote]=="active"
	let Icon =  userConfigured ? CheckIcon : AlertIcon
	let IconRemote = remoteConfigured ? CheckIcon : AlertIcon

	return <div className="side-info">
		<div className="info">
			<h3>{tr`title`}</h3>
			<img src="/proctoring.png" className="explainer-image" />
			<p>{tr`intro`}</p>
		</div>
		<div>
			<div className="columns">
				<div className={userConfigured ? "column col-11 camera-category-header camera-success" : "column col-11 camera-category-header camera-warn"}>
					{tr`setup_laptop`}
				</div>
				<div className={userConfigured ? "column col-1 text-right camera-category-header camera-success": "column col-1 text-right camera-category-header camera-warn"}>
					<Icon className={userConfigured ? "icon icon-success" : "icon icon-warn"} />
				</div>
				<div className="column col-12 columns camera-content">
					<div className="column col-6 text-center">
						<CameraControls key={user} recorder={recorder} streamType={user} state={streams[user]} recorder={recorder}/>
					</div>
					<div className="column col-6 text-center">
						{streams[user]!="active" ? null : 
							<CameraControls key={screen} recorder={recorder} streamType={screen} state={streams[screen]} recorder={recorder}/>
						}
					</div>
				</div>
				<div className={remoteConfigured ? "column col-11 camera-category-header camera-success" : "column col-11 camera-category-header camera-warn"}>
					{tr`setup_phone`}
				</div>
				<div className={remoteConfigured ? "column col-1 text-right camera-category-header camera-success": "column col-1 text-right camera-category-header camera-warn"}>
					<IconRemote className={remoteConfigured ? "icon icon-success" : "icon icon-warn"} />
				</div>	
				{ userConfigured ?
					<div className="column col-12 columns col-mx-auto">
						<CameraControls key={remote} recorder={recorder} streamType={remote} state={streams[remote]} recorder={recorder}/>
					</div>
				: 
					<div className="column col-12 columns col-mx-auto">
						<div className="col-12 dialog warn-dialog">
							{tr`laptop_first`}
						</div>
					</div>
				}
			</div>

			<button className={"next-button"+(starting ? " loading" : "")} disabled={streams.user!="active" || streams.screen!="active" || streams.remote!="active"} onClick={start}>{tr`start_recording`}</button>
		</div>
	</div>
}

const CameraControls = (props)=> {
	let {streamType, recorder, state} = props;
	if (streamType==="remote") return CameraControlsRemote(props);

	const tr = Translator.prefix('take_test.cameras');

	function toggle(e) {
		if (e.target.checked) recorder.add(streamType);
		else recorder.remove(streamType);
    }
	
	return <>
		<video ref={e => recorder.setElement(streamType, e)} muted autoPlay poster={`/poster-${streamType}.png`} className="preview" />
		<div className="column col-12 form-group">
			<label className="form-switch">
				<input type="checkbox" checked={state=="active" || state=="starting"} disabled={state==="starting"} onChange={toggle}></input>
				<i className="form-icon"></i>
				{streamType=='user' ? tr`enable_user` : tr`enable_screen`}
			</label>
		</div>
		{streamType==='user' && state==='denied' && <div className="toast toast-error">
			{tr`denied_user`}
		</div>}
		{streamType==='screen' && state==='invalidType' && <div className="toast toast-error">
			{tr`wrong_screen`}
		</div>}
	</>
}

const CameraControlsRemote = props => {
	let {streamType, recorder, state} = props;
	let [remoteKey] = useState(() => getRemoteKey());

	const tr = Translator.prefix('take_test.cameras');
    
    useEffect(function() {
        if (streamType==='remote' && state==='off') {
            // There's no need for a checkbox here; opening the QR
            // is the trigger.
            recorder.add(streamType, remoteKey);
        }    
	}, []);

	return <>
		<div className="column col-12 columns col-mx-auto my-2">
			{/* <div className="col-12 dialog info-dialog"> */}
				{tr`remote_instructions`}
			{/* </div> */}
		</div>
		<div className="column col-12 columns my-2">
			<div className="column col-6 text-center">
				<video className="preview" ref={e => recorder.setElement(streamType, e)} muted autoPlay poster={`/poster-${streamType}.png`} />
			</div>
			<div className="column col-6 text-center">
				<QRCode value={remoteKeyToUrl(remoteKey)} size={150} />
			</div>
		</div>
		<div className="column col-12 columns m-0 mb-2">
			<div className="column">{tr`qr_code`} <CopyText value={remoteKeyToUrl(remoteKey)} /></div>
		</div>
	</>;
}

function getRemoteKey() {
	let remoteKey = localStorage.getItem('remoteKey');
	if (remoteKey) return remoteKey;
	remoteKey = '';
	// Hard to confuse letters without vowels, to prevent accidental bad words:
	let alpha = "23456789bcdfghjklmnpqrstvwxz";
	for(let i=0; i<10; i++) {
		remoteKey += alpha[0 | (Math.random()*alpha.length)];
	}
	localStorage.setItem('remoteKey', remoteKey);
	return remoteKey;
}

function remoteKeyToUrl(remoteKey) {
	return `${location.protocol}//${location.host}/r/${remoteKey}`;
}

const VideoPreviews = ({recorder}) => {
	return <>
		<div className="column col-6 columns">
			<div className="column col-10 camera-category-header camera-success">
				{tr`laptop`.ucfirst()}
			</div>
			<div className="column col-2 text-right camera-category-header camera-success">
				<BatteryStatus recorder={recorder} source="local"/>
			</div>	
			<div className="column col-12 columns camera-content">
				<div className="column col-6 text-center">
					<video ref={e => recorder.setElement('user',e)} muted autoPlay poster="/testbeeld.png" className="preview"></video>
				</div>
				<div className="column col-6 text-center">
					<video ref={e => recorder.setElement('screen',e)} muted autoPlay poster="/testbeeld.png" className="preview"></video>
				</div>
			</div>
		</div>
		<div className="column col-6 columns ml-2">
			<div className="column col-10 camera-category-header camera-success">
				{tr`smartphone`.ucfirst()}
			</div>
			<div className="column col-2 text-right camera-category-header camera-success">
				<BatteryStatus recorder={recorder} source="remote"/>
			</div>
			<div className="column col-12 columns camera-content">
				<div className="column col-6 text-center">
					<video ref={e => recorder.setElement('remote',e)} muted autoPlay poster="/testbeeld.png" className="preview"></video>
				</div>
			</div>
		</div>
	</>
}

const BatteryStatus = ({recorder, source}) => {
	let bat = recorder.useBatteryStatus()[source] || {};
	return <div className={bat.charging || bat.level > 0.3 ? "battery-percentage" : "battery-percentage text-error"}>
		<span>{ bat.level==null ? "???" : Math.round(bat.level * 100)+'%' }</span>
		<i>
		{ bat.charging ?
			<BatteryChargingIcon />
		:
			<BatteryIcon />
		}
		</i>
	</div>
}

const IdCheck = ({data, recorder, nextStep}) => {
	const tr = Translator.prefix('take_test.id_check');
	
	return <>
		<h4>ID-check</h4>
		<div className="col-12 dialog info-dialog">
			{tr`intro`}
		</div>
		<div className="columns my-2 pt-2">
			<div className="column">
				<div>{tr`required`}</div>
				<ol>
					{tr`steps`}
				</ol>
			</div>
			<div className="column col-auto text-right">
				<video key="video" ref={e => recorder.setElement("user", e)} muted autoPlay poster="/testbeeld.png" className="check-preview" />
				<MarkInterval data={data} nextStep={nextStep} name='idCheck' text={tr`title`} />
			</div>
		</div>
	</>
}

const SaveButton = props => {
	const history = useHistory();
	let [saving, setSaving] = useState(false);

	async function clicked() {
		setSaving(true);
		let res = await props.getPromise();
		console.log('saving', res);
		setSaving(false);
		if (res.error) {
			history.replace({ state: {flash: res.error, flashType: "error"}} );
		} else {
			props.afterSave();
		}
	}

	return <button className={(saving ? "loading " : "") + "btn btn-primary"} disabled={saving || props.disabled} onClick={clicked}>{props.children}</button>
}

const MarkInterval = ({data, name, text, nextStep}) => {
	let [state, setState] = useState("start");
	let [times] = useState({});

	function toggle() {
		if (state==='start') times.start = new Date().toISOString();
		else if (state==='active') times.end = new Date().toISOString();
		setState({ready: 'start', start: 'active', active: 'ready'}[state]);
	}

	const getPromise = () => rpc.put(`/api/tests/${data.testKey}/${data.candidateKey}`, {[name]: times});

	let buttonText;
	if (state==='ready') buttonText = tr`mark_again`;
	else if (state==='start') buttonText = <><PlayIcon className="icon" /> {tr(`mark_begin`, {text})}</>;
	else buttonText =  <><StopIcon className="icon" /> {tr(`mark_end`, {text})}</>;

	return <div className="buttons">
			<button className={state=="ready" ? "btn mr-2" : "btn mr-2 btn-primary"} onClick={toggle}>{buttonText}</button>
			<SaveButton className="ml-2" disabled={state!="ready"} getPromise={getPromise} afterSave={nextStep}>{tr`next_step`}</SaveButton>
	</div>;
};

const RoomCheck = ({data, recorder, nextStep}) => {
	const tr = Translator.prefix('take_test.room_check');

	return <>
		<div className="columns">
			<div className="column">
				<h4>{tr`title`}</h4>
				<div>{tr`required`}</div>
				<ol>{tr`steps`}</ol>
			</div>
			<div className="column col-auto text-right">
				<video key="video" ref={e => recorder.setElement("remote", e)} muted autoPlay poster="/testbeeld.png" className="check-preview" />
				<MarkInterval data={data} nextStep={nextStep} name="roomCheck" text={tr`title`} />
			</div>
		</div>
	</>
};

const PerformTest = ({data, recorder, nextStep}) => {
	let [confirm, setConfirm] = useState(false);
	let startLeft = useMinutesLeft(data.test.startTime);
	let testLeft = useMinutesLeft(data.test.startTime) + parseInt(data.test.duration);

	const tr = Translator.prefix('take_test.perform_test');

	if (startLeft>0) {
		return <>
			<h4>{tr`please_wait`}</h4>
			<p>{tr(`start_in`, {startLeft})}</p>
			<VideoPreviews recorder={recorder} />
		</>;
	}

	async function openConfirmationDialog() {
		setConfirm(true);
	}
	async function closeConfirmationDialog() {
		setConfirm(false);
	}

	return <>
		<div className="columns dialog warn-dialog mb-2">
			{tr('remaining', {testLeft})}
		</div>
		<h4>{tr`title`}</h4>
		<div className="columns pr-2">
			<div className="column col-6  mt-0">
				<h5>{tr`instructions`}</h5>
				<p>{data.test.downloadInstruction}</p>
				{data.test.downloadLink && <a className="btn btn-primary" href={data.test.downloadLink} target="_blank">{tr`open_test`}</a>}
			</div>
			<div className="column col-6 dialog info-dialog mt-0">
				<h5>{tr`submit_title`}</h5>
				<p>{data.test.submissionInstruction}</p>
				{data.test.submissionLink && <p>
					<strong>{tr`submit_link`}</strong>
					{data.test.submissionLink.includes('@') ? 
						<a href={"mailto:"+data.test.submissionLink}>{data.test.submissionLink}</a>
					: 
						<a href={data.test.submissionLink} target="_blank">{data.test.submissionLink}</a>
					}
				</p>}
				<button className="btn btn-primary" onClick={openConfirmationDialog}>{tr`ready`}</button>
				{ !confirm ? null :
					<ConfirmDelivery data={data} recorder={recorder} onCancel={closeConfirmationDialog} nextStep={nextStep} />
				}
			</div>
		</div>
		<div className="columns mt-2">
			<VideoPreviews recorder={recorder} />
		</div>
	
	</>
};

const ConfirmDelivery = ({data, recorder, onCancel, nextStep}) => {
	const tr = Translator.prefix('take_test.confirm_delivery');

	async function wrapUp() {
		console.log('Wrapping up');
		nextStep();
		// ask the remote to stop the video feed (otherwise it may keep displaying video for hours, causing privacy concerns)
		await recorder.sendRemoteDone();
		// 'destroy' stops recording and closes all streams.
		recorder.destroy();
	}
	const getPromise = () => rpc.put(`/api/tests/${data.testKey}/${data.candidateKey}`, {submitEnd: new Date().toISOString()});

	return <div class="modal active" id="modal-id">
		<a href="#close" class="modal-overlay" aria-label="Close"></a>
		<div class="modal-container">
			<div class="modal-header">
				<a href="#close" class="btn btn-clear float-right" aria-label="Close"></a>
				<div class="modal-title h5">{tr`title`}</div>
			</div>
			<div class="modal-body">
				<div class="content">
					{tr`content`}
				</div>
			</div>
			<div class="modal-footer">
				<button className="btn mr-2" onClick={onCancel}>{tr`cancel`}</button>
				<SaveButton getPromise={getPromise} afterSave={wrapUp}>{tr`confirm`}</SaveButton>
			</div>
		</div>
	</div>
};


const Done = ({data}) => <div className="side-info">
	<div className="info">
		<Smile style={{width: '100%', height: '100%', margin: '0 auto', display: "block", stroke: "#white"}} />
	</div>
	<div>
		<h3>{tr`take_test.done.title`}</h3>
		{tr`take_test.done.close_tab`}
		<div className="buttons mt-2 pt-2">
			<button className="btn btn-sm" onClick={() => {localStorage.removeItem('candidateKey_'+data.testKey); location.reload(); }}>{tr`take_test.done.again`}</button>
		</div>
	</div>
</div>


const steps = [
	{
		getTitle: () => tr`start.title`,
		component: Start,
	},
	{
		getTitle: () => tr`about_you.title`,
		component: AboutYou,
		completed: s => s.agreePrivacy,
	},
	{
		getTitle: () => tr`cameras.title`,
        component: Cameras
	},
	{
		getTitle: () => tr`id_check.title`,
		component: IdCheck,
		mustRecord: true,
		completed: s => s.idCheck,
	}, 
	{
		getTitle: () => tr`room_check.title`,
		component: RoomCheck,
		mustRecord: true,
		completed: s => s.roomCheck,
	}, 
	{
		getTitle: () => tr`perform_test.title`,
		component: PerformTest,
		mustRecord: true,
		completed: s => s.submitEnd,
		exampleTestDelay: 20
	},
	{
        getTitle: () => tr`done.title`,
        component: Done,
		hidden: true,
		completed: s => false,
	}
];
