import React from 'react';
import freeice from 'freeice';
import * as rpc from '../services/rpc';
import tr from '../services/translator';

var peerConnectionConfig = {
    'iceServers': freeice()
};

export default class Remote extends React.Component {
    constructor() {
        super();
        this.state = {
            done: false,
            screenOrientation: 'unknown',
        };
        this.localE = React.createRef();

        this.onRemotePush = this.onRemotePush.bind(this);
        this.sendBatteryStats = this.sendBatteryStats.bind(this);
        this.toggleCam = this.toggleCam.bind(this);
    }


    async startUserStream() {
        let iceCnt = 0;
        this.peerConnection = new RTCPeerConnection(peerConnectionConfig);

        this.peerConnection.onicecandidate = e => {
            if (e.candidate) {
                this.setState({msg: `Sending ICE candidate ${++iceCnt}`});
                this.remoteChannel.send({ice: e.candidate});
            }
        }

        this.peerConnection.ontrack = e => {
            console.log('remote',e);
        };

        let batteryChannel = this.peerConnection.createDataChannel('battery');
        batteryChannel.onopen = e => {
            this.batteryChannel = batteryChannel;
            this.sendBatteryStats();
        };
        
        this.senders = [];
        for(let track of this.stream.getTracks()) {
            this.senders.push(
                this.peerConnection.addTrack(track, this.stream)
            );
        }
        //this.peerConnection.addStream(this.stream);

        this.setState({msg: "Creating streaming offer"});
        let description = await this.peerConnection.createOffer();

        this.setState({msg: "Setting streaming description"});
        await this.peerConnection.setLocalDescription(description);

        this.setState({msg: "Sending SDP to server"});
        this.remoteChannel.send({sdp: this.peerConnection.localDescription});
    }

    async initStream(facingMode) {
        let constraints = {
            video: {
                frameRate: 4,
                width: 800,
                resizeMode: 'none',
                facingMode
            },
            audio: true
        };
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.localE.current.srcObject = this.stream;
        this.localE.current.play();
    }

    componentDidUpdate(prevProps, prevState) { 
        if (prevState.screenOrientation == 'unknown' && this.state.screenOrientation == 'portrait') { 
            // The first time the user opens the page in portait mode we should do nothing.
            // When orientation is changed we should automatically start the stream.
            return 
        }
            
        if (prevState.done != this.state.done || 
            (prevState.screenOrientation != this.state.screenOrientation)) {
            this.componentWillUnmount();
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        screen.orientation.type.includes('landscape') ? 
            this.setState({ screenOrientation: 'landscape' }) 
            : this.setState({ screenOrientation: 'portrait' })

        window.addEventListener('orientationchange', () => {
            screen.orientation.type.includes('landscape') ?
                this.setState({ screenOrientation: 'landscape' })
                : this.setState({ screenOrientation: 'portrait' })
        });
        
        if (this.state.done || this.state.screenOrientation === 'unknown') return;

        this.setState({msg: "Accessing camera"});
        await this.initStream('environment');
        
        this.remoteChannel = new rpc.WebSocketChannel(this.props.match.params.remoteKey, this.onRemotePush);
        
        this.startUserStream();

        if (navigator.getBattery) {
            this.battery = await navigator.getBattery();
            this.battery.addEventListener('chargingchange', this.sendBatteryStats);
            this.battery.addEventListener('levelchange', this.sendBatteryStats);
        }
    }

    sendBatteryStats() {
        if (!this.battery || !this.batteryChannel) return;
        this.batteryChannel.send(JSON.stringify({
            charging: this.battery.charging,
            level: this.battery.level,
        }));
    }

    componentWillUnmount() {
        if (this.remoteChannel) {
            this.remoteChannel.close();
            this.remoteChannel = null;
        }
        if (this.battery) {
            this.battery.removeEventListener('chargingchange', this.sendBatteryStats);
            this.battery.removeEventListener('levelchange', this.sendBatteryStats);
            this.battery = null;
        }
        if (this.stream) {
            Remote.stopStream(this.stream);
            this.stream = null;
        }
    }

    static stopStream(stream) {
        for(let track of stream.getTracks()) {
            track.stop();
        }
    }

    onRemotePush(data) {
        if (data.trigger) {
            this.startUserStream();
        }
        if (data.sdpAnswer) {
            this.setState({msg: "Streaming!"});
            this.peerConnection.setRemoteDescription(data.sdpAnswer);
        }
        if (data.iceAnswer) {
            this.setState({msg: "Got ice"});
            this.peerConnection.addIceCandidate(new RTCIceCandidate(data.iceAnswer));
        }
        if (data.done) {
            this.setState({done: true});
        }
    }

    async toggleCam(e) {
        let facingMode = e.currentTarget.value;
        if (this.senders) {
            for(let sender of this.senders) {
                this.peerConnection.removeTrack(sender); 
            }    
        }
        if (this.stream) {
            Remote.stopStream(this.stream);
        }
        await this.initStream(facingMode);
        this.senders = [];
        for(let track of this.stream.getTracks()) {
            this.senders.push(
                this.peerConnection.addTrack(track, this.stream)
            );
        }
    }

    render() {
        let videoControls = 
            <div style={this.state.screenOrientation === 'portrait' ? {display: "none"} : {display: "block"}}> 
                <video style={{display: "inline-block"}}  ref={this.localE} muted playsInline autoPlay className="column col-auto preview"></video>
                { this.state.done ? 
                    <div>
                        <p>{tr`remote.stopped`}</p>
                        <button className="btn" onClick={e => this.setState({done: false})}>{tr`remote.start_again`}</button>
                    </div>
                :
                    <div className="form-group">
                        <label className="form-radio form-inline">
                            <input type="radio" name="gender" value="environment" defaultChecked={true} onChange={this.toggleCam} /><i className="form-icon" /> {tr`remote.rear`}
                        </label>
                        <label className="form-radio form-inline">
                            <input type="radio" name="gender" value="user" onChange={this.toggleCam} /><i className="form-icon" /> {tr`remote.front`}
                        </label>
                    </div>
                }
            </div>;

        return <main style={{textAlign: "center"}}>
            <h3>{tr`remote.header`}</h3>
            { this.state.screenOrientation === 'portrait'?
                <div className="columns">
                    <div className="column col-4"></div>
    				<div className="column col-4 my-2">
                        <img className="img-responsive" src="/phone-position.png" />
                        <div className="attribution mt-2">Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    </div>
                    <div className="column col-12 col-mx-auto dialog info-dialog">
                        {tr`remote.rotate_message`}
                    </div>
                    {this.stream ?
                    <div className="column col-12 col-mx-auto dialog warn-dialog">
                        {tr`remote.still_streaming_message`}
                    </div> : null }
                </div>
            : null } 
            { videoControls }
        </main>
    }

}
