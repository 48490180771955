import * as rpc from './rpc';

let storageToken;

// Don't call this method 'fetch' as it would clash with the browser's "fetch".
async function fetchStorage(relPath, opts = {}) {
    opts.headers = opts.headers || {};
    opts.method = opts.method || (opts.data ? 'POST' : 'GET');

    let resp;
    for(let attempt=1; attempt<=3; attempt++) {
        if (attempt==3 || (resp && (resp.status===403 || resp.status===401)) || !storageToken) {
            storageToken = await rpc.put('/api/storageToken', {old: storageToken});
        }
        opts.headers.Authorization = `Bearer ${storageToken}`;
        resp = await fetch(`https://storage.googleapis.com${relPath}`, opts);

        if (resp.status===201 || resp.status===200) {
            console.log('fetch ok', opts.method, relPath);
            return resp;
        } else {
            console.warn('fetch err', opts.method, relPath, resp.status, await resp.text());
        }
    }

    throw new Error(`fetch $`)
}

export {fetchStorage as fetch};
