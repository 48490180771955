import * as cloudStorage from '../services/cloud-storage';
import * as blobPacker from '../services/blob-packer';
import * as React from 'react';
import AwaitLock from 'await-lock';

const BUFFER_TIME = 30;
const LOG_EVENTS = ['error', 'stalled', 'ended', 'pause', 'emptied', 'suspend', 'waiting'];

export default class Player {

    constructor(types = ['user', 'screen', 'remote']) {
        this.types = types;
        this.rate = 4;
        this.elements = {};
        this._logEvent = this._logEvent.bind(this);
        this._sync = this._sync.bind(this);
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.setStates = [];
        this.startCount = 0;
    }


    _logEvent(e) {
        let type;
        for(let t in this.elements) {
            if (this.elements[t]===e.target) type = t;
        }
        console.info("video event", type, e.type, e);
    }

    _getTimeRangeString(tr) {
        let result = [];
        for(let i=0; i<tr.length; i++) {
            result.push(tr.start(i) + '-' + tr.end(i));
        }
        return result.join(' ');
    }

    setClips(clips, encKey) {
        this.clips = clips.sort();
        this.encKey = encKey;

        let times = this.clips.map(name => parseInt(name.split('-')[2]) / 1000);
        this.startTime = times[0];
        this.timestamps = times.map(t => t - this.startTime);
        this.duration = this.timestamps[this.timestamps.length-1] + 20; // just guessing the last clip is 20s
    }

    setElement(type, element) {
        this.elements[type] = element;
    }

    setRate(rate) {
        this.rate = rate;
        if (this.started) {
            this.changedRate = true;
            for(let type in this.elements) {
                this.elements[type].playbackRate = rate;
            }
        }
    }

    seek(wallTime) {
        this.time = wallTime - this.startTime;
        if (!this.started) return;
        if (this.time >= this.bufferStart && this.time <= this.bufferEnd+5) {
            // Just move the currentTime in the existing stream
            console.log('easy seek');
            this._sync();
        } else {
            // Restart the stream
            console.log('hard seek');
            this.start();
        }
    }

    // Elements should be set before start.
    start() {
        this.stop(); // initializes data structures
        this.started = true;

        // Skip clips before the startTime
        this.preloadClip = 0;
        while(this.preloadClip+1 < this.timestamps.length && this.timestamps[this.preloadClip+1] < this.time) {
            this.preloadClip++;
        }
        this.bufferStart = this.timestamps[this.preloadClip];
                        
        for(let type of this.types) {
            let lock = this.bufferLocks[type] = new AwaitLock();
            lock.tryAcquire(); // this should always succeed

            let ms = this.mediaSources[type] = new MediaSource();

            let onOpen = e => {
                console.info('sourceopen', type);
                ms.removeEventListener('sourceopen', onOpen);

                let sb = this.sourceBuffers[type] = ms.addSourceBuffer(type==='screen' ? 'video/webm; codecs=vp8' : 'video/webm; codecs=vp8,opus');
                
                sb.mode = 'segments';

                sb.addEventListener('error', e => console.warn('SourceBuffer err', type, e));
                lock.release();
            };

            ms.addEventListener('sourceopen', onOpen);

            let el = this.elements[type];
            for(let name of LOG_EVENTS) {
                el.addEventListener(name, this._logEvent);
            }
            el.src = URL.createObjectURL(this.mediaSources[type]);
            el.currentTime = this.time;
            el.playbackRate = this.rate;
        }

        this.syncInterval = setInterval(this._sync, 100);
        this.lastTime = Date.now() / 1000;

        let rate = this.rate;
        this.rate = 0; // rate will be set after preload
        this._sync(rate);
    }

    async stop() {
        // ignore any blob fetches/decodes that finish after this
        this.startCount++;

        if (this.started) {
            this.emitState({started: false});
            clearTimeout(this.syncInterval);
            for(let type of this.types) {
                // make sure all buffer append operations are finished
                this.bufferLocks[type].acquireAsync();

                let el = this.elements[type];
                let src = el.src;
                el.src = '';
                URL.revokeObjectURL(src);
                for(let name of LOG_EVENTS) {
                    el.removeEventListener(name, this._logEvent);
                }
            }
            this.started = false;
        }

        this.mediaSources = {};
        this.sourceBuffers = {};
        this.bufferLocks = {};
        this.bufferStart = 0;
        this.bufferEnd = 0;
    }

    useState() {
        let [state, setState] = React.useState(this.emittedState||{})
        React.useEffect(() => {
            this.setStates.push(setState);
            return () => {
                // Called when the caller goes out of scope
                this.setStates.splice(this.setStates.indexOf(setState));
            };
        }, [])
        return state;
    }

    emitState(state) {
        this.emittedState = state;
        for(let setState of this.setStates) {
            setState(state);
        }
    }

    async _sync(playRateOnLoad) {
        let now = Date.now() / 1000;
        this.time += (now - this.lastTime) * this.rate;

        // Pause on end of stream.
        if (this.time > this.duration) this.rate = 0;

        let leader = this.types[0];
        let leaderTime = this.elements[leader].currentTime;
        if (Math.abs(this.time - leaderTime) < 0.01*this.rate || this.changedRate || this.loading>0) {
            // We'll take the time for the leading element as our baseline if it's close enough,
            // or in case we changed the playback rate since the last _sync.
            //console.log(`set this.time from leader ${leader}`, this.time, '<--', leaderTime);
            this.time = leaderTime;
            this.changedRate = false;
        }
        this.lastTime = now;

        this.emitState({time: this.time+this.startTime, started: this.started, startTime: this.startTime, endTime: this.startTime+this.duration, rate: this.rate, loading: this.preloading!=null});

        //console.log('sync', Math.round(this.time*100)/100);

        for(let type in this.elements) {
            let delta = this.elements[type].currentTime - this.time;
            //console.log('delta', type, delta);
            if (Math.abs(delta) > 0.3*this.rate) {
                // TODO: perhaps use playbackRate to cancel out small differences
                // unless the player is stuck due to missing buffer parts.
//                console.log(`set ${type} from this.time`, this.elements[type].currentTime, '<--', this.time);
                this.elements[type].currentTime = this.time;
            } else if (Math.abs(delta) > 0.1*this.rate) {
                // Adjust the playback speed by 5% to match
                this.elements[type].playbackRate = this.rate * (delta > 0 ? 0.95 : 1.05);
            }
        }

        let promises = [];
        while(this.preloadClip < this.clips.length && this.timestamps[this.preloadClip] < this.time + BUFFER_TIME) {
            promises.push(this._fetchAndDecodeBuffer(this.preloadClip));
            this.preloadClip++;
            this.bufferEnd = this.timestamps[this.preloadClip] || this.duration;
        }

        // Prune buffers to 10m range
        this.bufferEnd = Math.min(this.bufferEnd, this.time+300);
        this.bufferStart = Math.max(this.bufferStart, this.time-300);

        if (playRateOnLoad!=null) {
            await Promise.all(promises);
            console.log('preloading done');
            this.rate = playRateOnLoad;
            for(let type in this.elements) {
                this.elements[type].play();
            }
        }

        // TODO: call MediaSource.endOfStream() when done
    }

    async _fetchAndDecodeBuffer(number) {
        if (this.preloading!=null) this.preloading++;
        let startCount = this.startCount;
        let rsp = await cloudStorage.fetch(`/storage/v1/b/toetshub-streams/o/${this.clips[number]}?alt=media`);
        let buffer = await rsp.arrayBuffer();
        
        if (!this.encKeyObj) {
            // Convert from hex to binary
            let encKeyBuffer = new Uint8Array(this.encKey.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            this.encKeyObj = await crypto.subtle.importKey('raw', encKeyBuffer, {name: "AES-GCM"}, false, ["encrypt", "decrypt"]);
        }

        let decrypted = await crypto.subtle.decrypt({name: "AES-GCM", iv: (new TextEncoder()).encode(this.clips[number])}, this.encKeyObj, buffer);

        if (this.startCount!==startCount) return; // no longer needed

        let parts = blobPacker.unpack(decrypted);

        for(let type in parts) {
            // Empty clips seem to be emitted when there is no <video> attached to the recorded stream.
            if (parts[type].byteLength===0) delete parts[type];
        }

        console.info('decoded', number);

        for(let type of this.types) {
            this._appendBuffer(type, number, parts[type]);
        }
    }

    async _appendBuffer(type, number, data) {
        let lock = this.bufferLocks[type];

        await lock.acquireAsync();
        try {
            if (data) {
                let sb = this.sourceBuffers[type];

                // abort() will not only cause the currently running 'append' to stop, but it
                // will also cause the internal demuxer to give up waiting for the rest of the
                // segment, discarding partial segments at the end of a stream. MediaRecorder
                // often leaves these partial segments.
                sb.abort();

                sb.timestampOffset = this.timestamps[number]; // sb.buffered.length ? sb.buffered.end(sb.buffered.length-1) : 0;

                sb.appendBuffer(data);
                await this._createSourceBufferPromise(sb);

                console.log('appended', type, this.timestamps[number], this._getTimeRangeString(sb.buffered));

                if (this.bufferStart>0) sb.remove(0, this.bufferStart);
                await this._createSourceBufferPromise(sb);
                sb.remove(this.bufferEnd, 999999999);
                await this._createSourceBufferPromise(sb);
            }

        } finally {
            lock.release();
        }
    }

    _createSourceBufferPromise(sb) {
        return new Promise( (accept, reject) => {
            if (!sb.updating) {
                accept();
                return;
            }
            sb.addEventListener('updateend', onUpdateEnd);
            function onUpdateEnd() {
                sb.removeEventListener('updateend', onUpdateEnd);
                accept();
            }
        });
    }
}
