import freeice from 'freeice';
import React from 'react';
import * as detectBrowser from 'detect-browser';

import * as BlobPacker from './blob-packer';
import * as cloudStorage from './cloud-storage';
import * as rpc from './rpc';

const browser = detectBrowser.detect();


function objEmpty(obj) {
    for(let name in obj) return false;
    return true;
}

// Lowest common denominator between chrome and firefox.
// We may switch to vp9 on Chrome, as Firefox MediaSource does
// seem to support it.

const vp = 'vp8';

let typeOptions = {
    user: {
        audioBitsPerSecond: 15000,
        videoBitsPerSecond: 150000,
        mimeType: `video/webm; codecs=${vp},opus`,
    },
    screen: {
        audioBitsPerSecond: 0,
        videoBitsPerSecond: 300000,
        mimeType: `video/webm; codecs=${vp}`,
    },
    remote: {
        audioBitsPerSecond: 15000,
        videoBitsPerSecond: 100000,
        mimeType: `video/webm; codecs=${vp},opus`,
    },
};


export default class Recorder {
    constructor(onStreamState) {
        this.onStreamState = onStreamState;
        this.elements = {};
        this.streams = {};
        this.streamStatus = {
            user: 'off',
            screen: 'off',
            remote: 'off'
        };
        this.streamStatusWatchers = [];
        this.batteryStatusWatchers = [];
        this.batteryStatus = {};
        this.started = false;

        this._updateBatteryStats = this._updateBatteryStats.bind(this);

        this._startBattery();
    }

    async add(type, arg) {
        console.log('recorder add', type, arg);
        if (this.streams[type]!=null) throw new Error(`Recorder already has ${type}`);

        this._setStreamStatus(type, 'starting');
        this.streams[type] = false;

        try {
            await this['_getStream_'+type](arg);
        } catch(e) {
            console.warn(`add ${type} err`, e);
            this._setStreamStatus(type, 'denied');
            this.streams[type] = undefined;
        }
    }

    setElement(type, el) {
        // Only one video element per type is supported at the momment.
        if (!el || el===this.elements[type]) return; // Prevent flicker when using as ref in React
        if (this.elements[type]) {
            this.elements[type].srcObject = null;
        }
        this.elements[type] = el;
        if (this.streams[type]) {
            el.srcObject = this.streams[type];
        }
    }

    /**
     * @returns {["user", "screen" or "remote"]: "off", "starting", "denied", "invalidType" or "active"}
     */
    useStreamStatus() {
        let [result,streamStatusWatcher] = React.useState(this.streamStatus);
        this.streamStatusWatchers.push(streamStatusWatcher);
        React.useEffect(() => () => {
            // When the calling component goes out of scope, we need to stop listening for updates.
            this.streamStatusWatchers.splice(this.streamStatusWatchers.indexOf(streamStatusWatcher), 1);
        }, []);
        return result;
    }

    _setStreamStatus(type, value) {
        // Make a copy, to make React notice the change.
        this.streamStatus = {...this.streamStatus, [type]: value};
        for(let w of this.streamStatusWatchers) {
            w(this.streamStatus);
        }
    }

    useBatteryStatus() {
        let [result,batteryStatusWatcher] = React.useState(this.batteryStatus);
        this.batteryStatusWatchers.push(batteryStatusWatcher);
        React.useEffect(() => () => {
            // When the calling component goes out of scope, we need to stop listening for updates.
            this.batteryStatusWatchers.splice(this.batteryStatusWatchers.indexOf(batteryStatusWatcher), 1);
        }, []);
        return result;
    }

    _setBatteryStatus(updates) {
        // Make a copy, to make React notice the change.
        this.batteryStatus = {...this.batteryStatus, ...updates};
        for(let w of this.batteryStatusWatchers) {
            w(this.batteryStatus);
        }
    }

    sendRemoteDone() {
        this.remoteChannel.send({done: true});
    }

    remove(type) {
        console.log('recorder remove', type);
        if (this.streams[type]==null) throw new Error(`Recorder doesn't have ${type}`);

        if (this.elements[type]) {
            this.elements[type].srcObject = null;
        }

        if (this.streams[type]) this._stopTracksForStream(this.streams[type]);

        if (this["_stopStream_"+type]) this["_stopStream_"+type]();

        this.streams[type] = undefined;
        this._setStreamStatus(type, 'off');
    }

    destroy() {
        this.stop();
        for(let type in this.streams) {
            if (this.streams[type]==null) continue;
            this.remove(type);
            this._setStreamStatus(type, 'off');
        }
        this._stopBattery();
        this.elements = {};
    }

    _stopTracksForStream(stream) {
        for(let track of stream.getTracks()) {
            try {
                track.stop();
            } catch(e) {
                console.warn("recorder stop track err", e);
            }
        }
    }

    async start(filePrefix, encKey) {
        console.log('recorder start');
        if (this.isStarted()) throw new Error("recorder already started");

        this.started = true;
        this.filePrefix = filePrefix;

        // Convert from hex to binary
        encKey = new Uint8Array(encKey.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        this.encKey = await crypto.subtle.importKey('raw', encKey, {name: "AES-GCM"}, false, ["encrypt", "decrypt"]);
        
        this.sendInterval = setInterval(this._recordClip.bind(this), 15*1000);
        this._recordClip();
    }

    isStarted() {
        return this.started;
    }

    stop() {
        console.log('recorder stop');
        this.started = false;
        this._stopMediaRecorders();
        clearTimeout(this.sendInterval);
    }


    _onStream(type, stream) {
        if (this.streams[type]==null) return; // removed already
        if (this.elements[type]) {
            this.elements[type].srcObject = stream;
        }

        stream.getVideoTracks()[0].addEventListener('ended', e => {
            console.warn('stream ended', type, e);
            this.remove(type);
        });

        let video = stream.getVideoTracks()[0];
        console.log('stream', type, video.getSettings && video.getSettings(), video.getCapabilities && video.getCapabilities());

        this.streams[type] = stream;
        this._setStreamStatus(type, 'active');

        if (this.isStarted()) this._recordClip();
    }

    async _getStream_user() {
        let constraints = {
            video: {
                frameRate: 15,
                width: 800,
                resizeMode: 'none'
            },
            audio: {
                channelCount: 1,
                echoCancellation: false,
            }
        };
        let stream = await navigator.mediaDevices.getUserMedia(constraints);
        this._onStream('user', stream);
    }

    async _getStream_screen() {
        let constraints = {
            video: {
                frameRate: 4,
                width: 1024,
                resizeMode: 'none'
            },
            audio: false
        };

        let stream = await navigator.mediaDevices.getDisplayMedia(constraints);
        let surface = stream.getVideoTracks()[0].getSettings().displaySurface;
        console.log('display surface', surface)
        if (surface === 'monitor' || !surface) {
            // Unfortunately, firefox doesn't report this property
            this._onStream('screen', stream);
        } else {
            this._stopTracksForStream(stream);
            this.streams.screen = undefined;
            this._setStreamStatus('screen', 'invalidType');
        }
    }

    _getStream_remote(remoteKey) {
        this.remoteChannel = new rpc.WebSocketChannel(remoteKey, this._onRemotePush.bind(this), {trigger: true});
    }

    _stopStream_remote() {
        this.remoteChannel.close();
        delete this.remoteChannel;
        if (this.remotePeer) {
            try {
                this.remotePeer.close();
            } catch(e) {}
            delete this.remotePeer;
        }
    }

    async _onRemotePush(data) {
        if (data.sdp) {
            this.remotePeer = new RTCPeerConnection({iceServers: freeice()});
            
            this.remotePeer.ontrack = e => {
                if (e.track.kind==="video") {
                    this._onStream('remote', e.streams[0]);
                }
            };
            this.remotePeer.onicecandidate = e => {
                if (e.candidate) {
                    this.remoteChannel.send({iceAnswer: e.candidate});
                }
            };
            this.remotePeer.ondatachannel = e => {
                if (e.channel.label === 'battery') {
                    e.channel.onmessage = e => {
                        console.log('remote battery', event.data);
                        this._setBatteryStatus({remote: JSON.parse(event.data)});
                    }
                }
            };
            this.remotePeer.onconnectionstatechange = e => {
                console.log('peer status', this.remotePeer.connectionState);
                if (this.remotePeer.connectionState==='disconnected') {
                    this._setStreamStatus('remote', 'starting');
                    this.streams.remote = false;
                    if (this.elements.remote) {
                        this.elements.remote.srcObject = null;
                    }
                }
            };
            await this.remotePeer.setRemoteDescription(data.sdp);
            if (!this.remotePeer) return; // raced by remove
            let answer = await this.remotePeer.createAnswer();
            await this.remotePeer.setLocalDescription(answer);
            if (!this.remoteChannel) return; // raced by remove
            this.remoteChannel.send({sdpAnswer: this.remotePeer.localDescription});
        }
        if (data.ice) {
            this.remotePeer.addIceCandidate(new RTCIceCandidate(data.ice));
        }
    }


    _stopMediaRecorders() {
        if (this.mrs) {
            for(let type in this.mrs) {
                console.log('stop', type, this.mrs[type]);
                this.mrs[type].stop();
            }
            delete this.mrs;
        }
    }

    _recordClip() {
        // Stop recording on all MediaRecorders; this cause ondataavailable to be called.
        this._stopMediaRecorders();

        let parts = {};
        let startTime = new Date();

        // TODO: add a timeout for the ondataavailable, just to be sure? Then we can at least upload 
        // any other streams. Also: report any problems violently!

        let mrs = this.mrs = {};
        for(let type in this.streams) {
            if (!this.streams[type]) continue; // still loading
            let mr = mrs[type] = new MediaRecorder(this.streams[type], typeOptions[type]);
            console.log('start', type, mr);
            mr.ondataavailable = e => {
                console.info('data', type, Math.round(e.data.size/1024)+'kb');
                if (!mrs[type]) console.warn('ondatavailable fired more than once', type, mr);
                delete mrs[type];
                parts[type] = e.data;

                if (objEmpty(mrs)) {
                    this._uploadClip(startTime, parts);
                }
            };
            mr.onerror = e => console.log('stream err', type, e);
            for(let i of ['pause','resume','start','stop']) {
                mr['on'+i] = e => console.log('stream on'+i, type, e);
            }
            mr.start();
        }
    }

    async _uploadClip(startTime, parts) {
        let clipName = `${this.filePrefix}-${+startTime}`;

        let blob = await BlobPacker.pack(parts);
        blob = await blob.arrayBuffer();

        let encrypted = await crypto.subtle.encrypt({name: "AES-GCM", iv: (new TextEncoder()).encode(clipName)}, this.encKey, blob);

        // Throws when there's an error. TODO: scream bloody murder in the user interface!
        await cloudStorage.fetch(`/upload/storage/v1/b/toetshub-streams/o?uploadType=media&name=${clipName}`, {
            method: 'POST',
            body: encrypted
        });
    }

    async _startBattery() {
        if (navigator.getBattery) {
            this.battery = await navigator.getBattery();
            this.battery.addEventListener('chargingchange', this._updateBatteryStats);
            this.battery.addEventListener('levelchange', this._updateBatteryStats);
            this._updateBatteryStats();
        }
    }

    _stopBattery() {
        if (this.battery) {
            this.battery.removeEventListener('chargingchange', this._updateBatteryStats);
            this.battery.removeEventListener('levelchange', this._updateBatteryStats);
        }
    }

    _updateBatteryStats() {
        this._setBatteryStatus({
            local: {
                charging: this.battery.charging,
                level: this.battery.level,
            }
        });
    }
}
