export function pack(obj) {
    let array = [null,null];
    let header = [];

    for(let name in obj) {
        header.push(name);
        header.push(obj[name].size);
        array.push(obj[name]);
    }
    array[1] = new Blob([JSON.stringify(header)]);
    if (array[1].size >= 36*36*36*36) throw new Error("Part description too long");
    array[0] = array[1].size.toString(36).padStart(4, '0');
    return new Blob(array);
}

export function unpack(buffer) {
    let encodedHeaderLen = new TextDecoder().decode(buffer.slice(0,4));
    let headerLen = parseInt(encodedHeaderLen,36);

    let headerJson = new TextDecoder().decode(buffer.slice(4,4+headerLen));
    let header = JSON.parse(headerJson);

    let result = {};
    let offset = 4+headerLen;

    for(let i=0; i<header.length; i++) {
        let name = header[i++];
        let size = header[i];
        result[name] = buffer.slice(offset,offset+size);
        offset += size;
    }

    if (offset !== buffer.byteLength) throw new Error("Trailing or missing data");

    return result;
}