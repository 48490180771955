import React from 'react';
import CopyIcon from "feather-icons/dist/icons/copy.svg";

function copy(event) {
    let el = event.target.parentNode.getElementsByTagName('input')[0];
    if (!el) return;

    el.select();
    el.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy"); /* Copy the text inside the text field */
}



export default function CopyText(props) {
    return <div className="has-icon-right">
        <input name="name" className="form-input" onFocus={e => e.target.select()} type="text" readOnly value={props.value} />
        <CopyIcon className="form-icon" onClick={copy} />
    </div>;
}