import React from 'react';


export const useMinutesLeft = time => {
    let left = + new Date(time) - new Date();
    let minutes = Math.ceil(left / 60000);
    let [dummy,setDummy] = React.useState();
    if (minutes > 0) {
        let updateTime = left - (minutes-1)*60000 + 100; // 100ms after full second, to be safe
        setTimeout(function() {
            setDummy(left);
        }, updateTime);
    }
    return minutes;
}