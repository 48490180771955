import React, { createElement } from 'react';
import Player from '../services/player';
import * as rpc from '../services/rpc';
import {Link} from 'react-router-dom';

import PlayIcon from "feather-icons/dist/icons/play.svg";
import StopIcon from "feather-icons/dist/icons/stop-circle.svg";


class Controller {
    constructor(player) {
        this.player = player;
    }
    setStudent(student) {
        this.startTime = student.idCheck ? new Date(student.idCheck.start)/1000 - 5 : 0;
        this.player.seek(this.startTime);
    }
}

export default function Proctoring(props) {
    let testKey = props.match.params.testKey;
    let candidateKey = props.match.params.candidateKey;
    let [msg,data] = rpc.useResult(`/api/tests/${testKey}/${candidateKey}/proctoring`);
    
    let [player] = React.useState(() => new Player());
    let [controller] = React.useState(() => new Controller(player));

    let [pos, setPos] = React.useState(['screen','user','remote']);

    let playerState = player.useState();

    let student = data && (data.candidate.student || {});

    React.useEffect(() => {
        if (msg) return;
        player.setClips(data.clips, data.candidate.encKey);
        controller.setStudent(student);
    }, [msg]);

    if (msg) return msg;

    let grow = type => {
        let newPos = pos.filter(p => p!=type);
        newPos.unshift(type);
        setPos(newPos);
    }

    function seek(e) {
        let el = e.currentTarget;
        let value = (e.pageX - el.offsetLeft) * el.max / el.offsetWidth;
        player.seek(value + controller.startTime);
    };

    let playPause = playerState.started ? 
        <StopIcon onClick={() => player.stop()} /> :
        <PlayIcon onClick={() => player.start()} />;

    const speeds = [0,1,4,8,15];

    return <>
        <ul className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to="/tests">Toetsen</Link>
            </li>
            <li className="breadcrumb-item">
                <Link to={"/tests/"+data.candidate.testKey}>{data.test.name}</Link>
            </li>
            <li className="breadcrumb-item">
                #{student.studentId || '???'}
            </li>
        </ul>
        <h3>{student.fullName || '???'}</h3>
        {/*<div>{JSON.stringify(data)}</div>*/}
        <div className="videos columns proctoring">
            <div className="controls">
                <div>
                    {playPause}
                    {speeds.map(speed => <span key={speed} onClick={e => player.setRate(speed)} style={{marginLeft: "1em", cursor: "pointer", color: playerState.rate==speed ? 'white' : 'inherit'}}>{speed}x</span>)}
                </div>
                <div>{playerState.endTime && new Date(playerState.time*1000).toLocaleTimeString()}</div>
                {playerState.endTime && <progress onClick={seek} style={{width: '20%'}} className="progress" value={playerState.time-controller.startTime} max={playerState.endTime-controller.startTime} />}
            </div>
            <video key="user" onClick={e => grow("user")} className={"pos"+pos.indexOf('user')} ref={el => player.setElement('user', el)}></video>
            <video key="remote" onClick={e => grow("remote")} className={"pos"+pos.indexOf('remote')} ref={el => player.setElement('remote', el)}></video>
            <video key="screen" onClick={e => grow("screen")} className={"pos"+pos.indexOf('screen')} ref={el => player.setElement('screen', el)}></video>
        </div>
    </>;

}
