import React from 'reactn';

export class WebSocketChannel {

    // In case of reconnect, initMsg is repeated as well.
    constructor(channel, cb, initMsg) {
        this.channel = channel;
        this.cb = cb;
        this.initMsg = initMsg;
        this.connect();
    }

    connect() {
        this.close();
        console.info('ws connect');
        let ws = this.ws = new WebSocket((location.protocol==='http:' ? 'ws://' : 'wss://') + location.host + '/channels/'+this.channel);
        
        ws.onmessage = this.onMessage.bind(this);
        ws.onopen = this.onOpen.bind(this);
        ws.onerror = this.onError.bind(this);
        ws.onclose = this.onClose.bind(this);
        this.onOpenResolves = [];
        if (this.initMsg) this.send(this.initMsg);
    }

    onMessage(e) {
        console.info('ws-in', e.data);
        this.cb(JSON.parse(e.data));
    }

    onOpen(e) {
        if (this.ws !== e.target) return; // raced
        for(let resolve of this.onOpenResolves) resolve();
        this.onOpenResolves = false;
    }

    onError(e) {
        if (this.ws !== e.target) return; // raced
        console.warn('ws err', e);
        this.ws = null;
        this.close();
        setTimeout(this.connect.bind(this), 1000);
    }

    onClose(e) {
        if (this.ws !== e.target) return; // raced
        console.warn('ws closed');
        this.ws = null;
        this.close();
        this.timeout = setTimeout(this.connect.bind(this), 1000);
    }

    getOpenPromise() {
        return new Promise((resolve,reject) => {
            if (this.onOpenResolves) this.onOpenResolves.push(resolve);
            else resolve();
        });
    }

    async send(data) {
        await this.getOpenPromise();
        let json = JSON.stringify(data);
        console.info('ws-out', json);
        this.ws.send(json);
    }

    close() {
        clearTimeout(this.timeout);
        if (this.ws) {
            try {
                this.ws.close();
            } catch(e) {
                console.warn('ws close', e);
            }
            this.ws = null;
        }
        if (!this.onOpenResolves) this.onOpenResolves = [];
    }
}


export async function rest(method, path, data) {
    let user = React.getGlobal('user');
    let opts = {
        method,
        headers: {
            auth: localStorage.getItem('authToken')
        },
    };
    if (data!==undefined) {
        opts.headers['Content-Type'] = 'application/json';
        opts.body = JSON.stringify(data);
    }
    let resp = await fetch(path, opts);
    return await resp.json(); 
}

export function get(...args) { return rest('GET', ...args); }
export function post(...args) { return rest('POST', ...args); }
export function put(...args) { return rest('PUT', ...args); }
export function del(...args) { return rest('DELETE', ...args); }

export function useResult(path, method='GET', data) {
    let [result,setResult] = React.useState();
    React.useEffect(() => {
        (async function(){
            setResult(await rest(method, path, data));
        })();        
    }, []);
    if (result===undefined) return [<div className="loading loading-lg"></div>];
    if (result && result.error) return [<div className="toast toast-error">{result.error}</div>];
    return [undefined,result];
}
