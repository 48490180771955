import ReactDOM from 'react-dom';
import React from 'reactn';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    withRouter
  } from "react-router-dom";
import * as detectBrowser from 'detect-browser';

import * as auth from './services/auth';
import * as rpc from './services/rpc';
import tr from './services/translator';

import {TakeTest, TakeTestSteps} from './pages/take-test';
import Remote from './pages/remote';
import FrontPage from './pages/front-page';
import TeacherEditTest from './pages/teacher-edit-test';
import TeacherTestList from './pages/teacher-test-list';
import TeacherTest from './pages/teacher-test';
import Proctoring from './pages/proctoring';

import './default.scss';
import Logo from './logo.svg';

import PhoneCallIcon from "feather-icons/dist/icons/phone-call.svg";



// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({...props}) {
    let [user] = React.useGlobal('user');
    if (user===undefined) {
        return <div className="loading"></div>;
    } else if (user===null) {
        delete props.component;
        props.children = <Redirect to={{pathname: "/login", state: {flash: tr("signin.required"), redirect: props.location.pathname}}} />;
    }
    return <Route {...props} />;
}


const Flash = withRouter(props => {
    let [seen, setSeen] = React.useState();
    let state = props.history.location.state;
    if (!seen && state && state.flash) {
        return <div className={"toast"+(state.flashType ? " toast-"+state.flashType : "")}>
            <button className="btn btn-clear float-right" onClick={e => setSeen(true)}></button>
            {state.flash}
        </div>;
    }
    return <></>;
});


const Header = withRouter(props => {
    let [user] = React.useGlobal('user');
    let [step] = React.useGlobal('takeTestStep');

    function signOut() {
        auth.signOut();
        props.history.push('/');
    }

    return <header>
        <div className="navbar app-container py-2">
            <section className="navbar-section">
                <Link to="/" className="navbar-brand text-bold"><Logo className="logo" /></Link>
            </section>
            <section className="navbar-section">
                {/* <a href="..." className="btn btn-link">Docs</a> */}
                {
                    step!=null ?
                        <TakeTestSteps step={step} />
                    : user ?
                        <button className="btn" onClick={signOut}>{tr('header.logout')}</button>
                    :
                        null
                }
            </section>
            <section className="navbar-section flag-help">
                <div className="flags">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" className={props.language=="nl" ? "selected" : null} onClick={e => props.setLanguage('nl')}>
                        <path fill="#21468b" d="M0 0h640v480H0z"/>
                        <path fill="#fff" d="M0 0h640v320H0z"/>
                        <path fill="#ae1c28" d="M0 0h640v160H0z"/>
                    </svg>
                    <div className="spacer"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" className={props.language=="en" ? "selected" : null} onClick={e => props.setLanguage('en')}>
                        <path fill="#012169" d="M0 0h640v480H0z"/>
                        <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                        <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                    </svg>
                </div>
                <Link className="btn" onClick={() => alert('Todo!')}><PhoneCallIcon className="icon" /> {tr`get_support`}</Link>
            </section>
        </div>
    </header>;
});

function logError(msg) {
    let browser = {};
    let user = React.getGlobal('user');
    try {
        browser = detectBrowser.detect();
    } catch(e) {}
    let details = msg+`
    user ${user && user.email}
    browser ${browser.name}/${browser.version}/${browser.os}
    time ${new Date()}
    host ${location.host}
    path ${location.pathname}`;
    console.log(details);
    rpc.post('/api/errors', {details});
}

window.onerror = function(msg, file, line, col, error) {
    logError(error ? error.stack : `${msg}\n\tat ${file}:${line}`);
}

class App extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.setLanguage = this.setLanguage.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        logError(error.stack+errorInfo.componentStack);
    }

    static getDerivedStateFromError(error) {
        return {error: error.message, errorPath: location.pathname};
    }

    setLanguage(language) {
        tr.setLanguage(language);
        this.forceUpdate();
    }
    
    render() {
        if (this.state.error) {
            return <main className="app-container">
                {tr("app.error")}
                <div className="btn-group">
                    <button onClick={e => this.setState({error: null})} className="btn">{tr`app.error_try_again`}</button>
                    <button onClick={e => location.href = '/'} className="btn">{tr`app.error_to_start`}</button>
                </div>
                <p></p>
                <code>{this.state.error}</code>
            </main>
        }
        return <Router>
            <Header setLanguage={this.setLanguage} language={tr.getLanguage()} />
            <main className="app-container">
                <Flash />
                <Switch>
                    <PrivateRoute path="/tests/:testKey/:candidateKey/proctoring" component={Proctoring} />
                    <PrivateRoute path="/tests/new" component={TeacherEditTest} />
                    <PrivateRoute path="/tests/:testKey/edit" component={TeacherEditTest} />
                    <PrivateRoute path="/tests/:testKey" component={TeacherTest} />
                    <PrivateRoute path="/tests" component={TeacherTestList} />
                    <Route path="/t/:testKey" component={TakeTest} />
                    <Route path="/r/:remoteKey" component={Remote} />
                    <Route path="/signin/:code" component={auth.LinkSignin} />
                    <Route path="/login" component={auth.Login} />
                    <Route component={FrontPage} />
                </Switch>
            </main>
        </Router>;
    }
};
    
ReactDOM.render(<App />, document.getElementById('root'));
