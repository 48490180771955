import React from "reactn";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import * as rpc from './rpc';
import tr from "./translator";

import MailIcon from "feather-icons/dist/icons/mail.svg";

async function login() {
    if (localStorage.getItem('authToken')) {
        let res = await rpc.get('/api/me');
        if (res.user) {
            React.setGlobal({user: res.user});
        } else {
            React.setGlobal({user: null});
            localStorage.removeItem('authToken');
        }
    } else {
        React.setGlobal({user: null});
    }
}
login();

export function requestLoginLink(email, redirect) {
    return rpc.post('/api/me/codeRequests', {
        email,
        baseUrl: location.protocol+'//'+location.host,
        redirect,
        language: tr.getLanguage()
    });
}

export function signOut() {
    localStorage.removeItem('authToken');
    React.setGlobal({user: null});
}

export function LinkSignin(props) {
    let history = useHistory();

    async function checkCode() {
        let result = await rpc.put('/api/me', {code: props.match.params.code});
        if (result.user) {
            React.setGlobal({user: result.user})
            localStorage.setItem('authToken', result.authToken);
            history.replace(result.redirect || '/');
        } else {
            React.setGlobal({user: null})
            history.replace("/", {flash: tr(result.error), flashType: "warning"});
        }
    }

    // We're using an intermediate checkToken function here, as useEffect doesn't like async.
    React.useEffect(function() { checkCode() }, []);

    return <><h2>{tr`signin.please_wait`}</h2><div className="loading" /></>
}



export function Login(props) {
    let [login,setLogin] = React.useState(true);
    let [error,setError] = React.useState();
    
    let location = useLocation();
    let redirect = (location.state||{}).redirect || '/';

    let [user] = React.useGlobal('user');

    if (user) return <Redirect to={redirect} />;

    if (login===false) {
        return <div className="loading loading-lg"></div>;
    }
    if (login===true) {
        return <form onSubmit={onSubmit}>
            {error && <div className="toast error">{error}</div>}
            <div className="form-group">
                <label>
                    <span className="form-label">{tr`email`.ucfirst()}:</span>
                    <div className="has-icon-left">
                        <input name="email" className="form-input" type="email" defaultValue={localStorage.getItem('email')||''}/>
                        <MailIcon className="form-icon" />
                    </div>
                </label>
            </div>
            <div className="form-group">
                <input type="submit" className="btn btn-primary" value={tr`signin.send_link`} />
            </div>
        </form>;
    } 
    return <>
        <p>{tr('signin.link_sent', {email: login})}</p>
        <button className="btn" onClick={e => setLogin(true)}>Opnieuw</button>
    </>;


    async function onSubmit(e) {
        e.preventDefault();

        let email = e.target.email.value;
        if (email.indexOf('@')>=0) {
            setLogin(false);
            let res = await requestLoginLink(email, redirect);
            if (res.error) {
                setError(res.error);
                setLogin(true);
            } else {
                setLogin(email);
                localStorage.setItem('email', email);
                localStorage.setItem('emailForSignIn', email);
            }
        }
    }
}