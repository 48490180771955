import React from 'react';
import {Link} from 'react-router-dom';
import Translator from '../services/translator';

export default function FrontPage(props) {

	let tr = Translator.prefix('front');

	return <>
		<div className="hero hero-sm bg-dark my-2">
			<div className="hero-body">
				<h1>{tr`tagline`}</h1>
				<p>{tr`description`}</p>
				<div className="buttons">
					<Link className="btn btn-primary" to="/t/example">{tr`example_test`}</Link>
				</div>
			</div>
		</div>
		<div className="columns my-2">
			<div className="column col-4">
				<div className="toast toast-warning p-1">
					{tr`warning`}
				</div>
			</div>
			<div className="column col-4">
				<div className="card">
					<div className="card-header">
						<div className="card-title h5">{tr`teacher`}</div>
						<div className="card-subtitle text-gray">{tr`teacher_text`}</div>
					</div>
					<div className="card-footer buttons">
						<Link className="btn" to="/tests">{tr`manage_tests`}</Link>
					</div>
				</div>
			</div>
			<div className="column col-4">
				<div className="card">
					<div className="card-header">
						<div className="card-title h5">{tr`student`}</div>
						<div className="card-subtitle text-gray">{tr`student_text`}</div>
					</div>
				</div>
			</div>
		</div>
	</>;
}
