import React from 'react';
import { Link } from 'react-router-dom';
import * as rpc from '../services/rpc';

export default function TeacherTestList(props) {
    let [msg,list] = rpc.useResult('/api/tests');
    if (msg) return msg;

    return <div className="container">
        <div className="columns">
            <div className="column col-12">
            {list.length ?
                <table className="table">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Naam</th>
                            <th>Eigenaar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(item => <tr key={item.key}>
                            <td>{item.startTime.replace(/T.*/,'')}</td>
                            <td><Link to={"/tests/"+item.key}>{item.name}</Link></td>
                            <td>{item.ownerEmail}</td>
                        </tr>)}
                    </tbody>
                </table>
            :
                <div className="empty">
                    <div className="empty-icon">
                        <i className="icon icon-4x icon-link"></i>
                    </div>
                    <p className="empty-title h5">Je hebt nog geen toetsen.</p>
                    <p className="empty-subtitle">Klik op de onderstaande knop om er één aan te maken.</p>
                </div>
            }
            </div>
            <div className="column pt-2">
                <Link to="/tests/new" className="btn btn-primary"><i className="icon icon-plus" /> Toets aanmaken</Link>
            </div>
        </div>
    </div>
}