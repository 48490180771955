import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CopyText from '../components/copy-text';
import * as rpc from '../services/rpc';
import EditIcon from "feather-icons/dist/icons/edit.svg";


export default function TeacherTest(props) {
    let testKey = props.match.params.testKey;
    let [msg,info] = rpc.useResult("/api/tests/"+testKey);
    if (msg) return msg;

    let candidates = info.candidates.sort((a,b) => !a.student ? 1 : !b.student ? -1 : (a.student.fullName+'').toUpperCase() < (b.student.fullName+'').toUpperCase() ? -1 : 1 );
    
    return <>
        <BreadCrumb name={info.test.name} />
        <Link style={{float: 'right'}} to={`/tests/${testKey}/edit`}><EditIcon /></Link>
        <h3>{info.test.name}</h3>

        <table className="table"><tbody>
            <tr>
                <th>Datum:</th>
                <td>{new Date(info.test.startTime).toLocaleDateString('NL-nl')}</td>
            </tr>
            <tr>
                <th>Aanvangstijd:</th>
                <td>{new Date(info.test.startTime).toLocaleTimeString('NL-nl')}</td>
            </tr>
            {/*
            <div className="form-group">
                <div className="col-1 col-mr-auto"></div>
                <div className="col-1">
                    <input type="submit" className="btn btn-primary" value="Aanmaken" />
                </div>
                <div className="col-1">
                    <Link to="/tests" className="btn btn-secondary">Annuleren</Link>
                </div>
            </div>
            */}

            <tr>
                <th>Toets URL:</th>
                <td>
                    <CopyText value={`${location.protocol}//${location.host}/t/${props.match.params.testKey}`} />
                    <div>Te gebruiken door studenten.</div>
                </td>
            </tr>
        </tbody></table>

        {/* <Link to="/tests" className="btn"><i className="icon icon-arrow-left" /> Terug</Link> */}
        <div className="spacer my-2 pt-2" />
        <h3>Deelnemers</h3>
        {info.candidates.length ?
            <table className="table">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Aanvang</th>
                        <th>Eind</th>
                    </tr>
                </thead>
                <tbody>
                    {candidates.map(c => <tr key={c.key}>
                        <td><Link to={`/tests/${testKey}/${c.key}/proctoring`}>{(c.student||{}).fullName || 'Nog onbekend'}</Link></td>
                        <td>{c.startTime || '-'}</td>
                        <td>{c.endTime || '-'}</td>
                    </tr>)}
                </tbody>
            </table>
        :
            <div className="empty">
                <div className="empty-icon">
                    <i className="icon icon-4x icon-link"></i>
                </div>
                <p className="empty-title h5">Nog niemand is begonnen met de toets-procedure.</p>
                <p className="empty-subtitle">Zorg dat je studenten de bovenstaande link <strong>voortijdig testen</strong> en zeker <strong>tien minuten voor aanvang</strong> van de toets de procedure te starten.</p>
            </div>
        }
    </>;
}

const BreadCrumb = ({name}) => (
    <ul className="breadcrumb">
        <li className="breadcrumb-item">
            <Link to="/tests">Toetsen</Link>
        </li>
        <li className="breadcrumb-item">
            {name}
        </li>
    </ul>
)
