import React from 'reactn';
import {Link, useHistory } from 'react-router-dom';
import * as rpc from '../services/rpc';

export default function TeacherEditTest(props) {
    let testKey = props.match && props.match.params.testKey;

    let [sending,setSending] = React.useState(false);
    let history = useHistory();

    let test = {};
    if (testKey) {
        let [msg,info] = rpc.useResult("/api/tests/"+testKey);
        if (msg) return msg;
        test = info.test;

        if (test.ownerUid !== React.getGlobal().user.key) return <>
            <div className="toast toast-error">
                Alleen de aanmaker ({test.ownerEmail}) kan de toets aanpassen.
            </div>
            <button onClick={e => history.goBack()}>Terug</button>
        </>;
    }

    async function submit(e) {
        e.preventDefault();
        setSending(true);
        let obj = {};
        for(let {name, value, type, checked} of e.target.elements) {
            obj[name] = type == "checkbox" ? checked : value
        }

        let json = await (testKey ? rpc.put('/api/tests/'+testKey, obj) : rpc.post('/api/tests', obj));

        setSending(false);

        if (json.error) {
            history.replace({ state: {flash: error, flashType: "error"}} );
        } else {
            history.replace("/tests/"+json.testKey);
        }
    }
    
    let startTime = test.startTime ? test.startTime.replace(/:\d\d\.\d\d\dZ$/,'') :  '2020-12-31T09:00';

    return <form className="form-horizontal " onSubmit={submit}>
        <fieldset disabled={sending}>
            <label className="form-group">
                <span className="col-3 form-label">Toetsnaam</span>
                <input name="name" className="col-9 form-input" type="text" defaultValue={test.name} required />
            </label>
            <label className="form-group">
                <span className="col-3 form-label">Aanvangstijd</span>
                <input name="startTime" className="col-9 form-input" type="datetime-local" defaultValue={startTime} required />
            </label>
            <label className="form-group">
                <span className="col-3 form-label">Duur in minuten</span>
                <input name="duration" className="col-9 form-input" type="number" defaultValue={test.duration} required />
            </label>
            <hr />
            <label className="form-group">
                <span className="col-3 form-label">Toets-instructies</span>
                <textarea name="downloadInstruction" className="col-9 form-input" type="text" defaultValue={test.downloadInstruction} />
            </label>
            <label className="form-group">
                <span className="col-3 form-label">Link naar de toets</span>
                <input name="downloadLink" className="col-9 form-input" type="text" defaultValue={test.downloadLink} />
            </label>
            <label className="form-group">
                <span className="col-3 form-label">Inlever-instructies</span>
                <textarea name="submissionInstruction" className="col-9 form-input" type="text" defaultValue={test.submissionInstruction} />
            </label>
            <label className="form-group">
                <span className="col-3 form-label">Link naar het inleverpunt</span>
                <input name="submissionLink" className="col-9 form-input" defaultValue={test.submissionLink} type="text" />
            </label>
            <div className="toast mb-2">
                Let op, je e-mailadres ({React.getGlobal().user.email}) zal zichtbaar zijn voor studenten. 
                Zorg dat dit voor hen een herkenbaar en vertrouwd adres is.
            </div>
            <div className="form-group buttons">
                <Link to="/tests" className="btn btn-secondary">Annuleren</Link>
                <input type="submit" className="btn btn-primary" value={testKey ? "Bijwerken" : "Aanmaken"} />
            </div>
        </fieldset>
    </form>;
}
